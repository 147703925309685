import { fetchFile } from '@ffmpeg/util';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { AudioTrackRegionType, AudioTrackType, FFmpegFilterOptions } from './types';
import { CampaignSpotTrackType, CampaignSpotTrackFiltersType } from '../api/resources/v1/campaign';
import { ConfigAudioTemplateType } from '../api/resources/v1/config/audioTemplate';
import { Logger } from '../utils/log';
import { startPerformance } from '../utils/performance';
import { TRACK_EVENTS, applicationInsights } from '../azure/ApplicationInsights';
import { amix } from './ffmpeg';
import api from '../api/api';

export async function files(items: Array<{ data?: string }>, ffmpeg: FFmpeg) {
  const inputs: string[] = [];

  for (let i = 0; i < items.length; i++) {
    const track = items[i];
    const filename = `input${i}.mp3`;
    inputs.push('-i', filename);
    await ffmpeg.writeFile(filename, await fetchFile(track.data));
  }

  return inputs;
}

export function stringifyFFmpegParams(obj: any, orderedKeys: string[] = []) {
  const sortedEntries = Object.entries(obj).sort(([keyA], [keyB]) => {
    const indexA = orderedKeys.indexOf(keyA);
    const indexB = orderedKeys.indexOf(keyB);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    } else {
      return keyA.localeCompare(keyB);
    }
  });

  return sortedEntries.map(([key, value]) => `${key}=${key === 'adelay' ? `${value}:all=1` : value}`).join(',');
}

export function generateFilterComplex(regions: AudioTrackRegionType[], mastering: string[] = []) {
  const args = ['-filter_complex'];

  // Filter Graph

  const filters = regions.map((region, i) => {
    let regionFilter = `[${i}]${stringifyFFmpegParams(region.filters, ['atempo', 'adelay'])}`;

    // Resample each audio track to 44.1 kHz
    regionFilter += ',aresample=44100';

    // when the audio is mono, apply mono to stereo technique
    if (region.channels === 1) {
      regionFilter += ',pan=stereo|c0=c0|c1=c0';
    }

    return `${regionFilter}[a${i}];`;
  });

  const inputs = [...Array(regions.length)]
    .map((u, i) => i)
    .reverse()
    .reduce((a, b) => `[a${b}]${a}`, '');

  filters.push(`${inputs}amix=inputs=${regions.length}:duration=longest`);

  // Mastering
  filters.push(`,${mastering.join(',')}`);

  args.push(filters.join(''));

  return args;
}

export function filter(options: FFmpegFilterOptions = {}) {
  const args = ['-af'];
  args.push(stringifyFFmpegParams(options));
  return args;
}

export function getAutoADelay(automaticADelayTrackId: string, spotTracks: CampaignSpotTrackType[], audioTemplate: ConfigAudioTemplateType) {
  const spotTrack = spotTracks.find(a => Number(a.audioTemplateTrackConfigId) === Number(automaticADelayTrackId));
  if (!spotTrack) return '0';

  const audioTemplateTrack = audioTemplate.tracks?.find(audioTemplateTrack => Number(audioTemplateTrack.id) === Number(spotTrack.audioTemplateTrackConfigId));
  const filtersApplied: CampaignSpotTrackFiltersType = spotTrack.filters ? JSON.parse(spotTrack.filters) : {};
  const adelay = filtersApplied.adelay ? Number(filtersApplied.adelay) : audioTemplateTrack?.adelay ? Number(audioTemplateTrack.adelay) : 0;
  const atempo = filtersApplied.atempo ? Number(filtersApplied.atempo) : 1;
  const duration = (spotTrack.audioInfo.duration || 0) * 1000;

  const autoADelay = String(adelay + duration / atempo);
  return autoADelay;
}

export async function prepareTracksForMixing(audioTemplate: ConfigAudioTemplateType, spotTracks: CampaignSpotTrackType[] | undefined = []): Promise<AudioTrackType[]> {
  const audioTracks: AudioTrackType[] = [];
  const audioTemplateTracks = audioTemplate.tracks || [];

  for (let i = 0; i < audioTemplateTracks.length; i++) {
    const audioTemplateTrack = audioTemplateTracks[i];
    const spotTrack = spotTracks.find(a => Number(a.audioTemplateTrackConfigId) === Number(audioTemplateTrack.id));

    if (spotTrack && spotTrack.assetId) {
      const metadata = spotTrack.audioInfo;
      const audioDuration = metadata.duration * 1000;
      const channels = metadata.numberOfChannels || 1;

      const filtersApplied: CampaignSpotTrackFiltersType = spotTrack.filters ? JSON.parse(spotTrack.filters) : {};

      // track volume
      const volume = filtersApplied.volume ? String(filtersApplied.volume) : '1';

      // adelay // audio offset in the mix
      let adelay = '0';
      if (filtersApplied.adelay) {
        adelay = String(filtersApplied.adelay);
      } else if (audioTemplateTrack.isAutomaticADelay && audioTemplateTrack.automaticADelayTrackId) {
        // automatic delay
        adelay = getAutoADelay(audioTemplateTrack.automaticADelayTrackId, spotTracks, audioTemplate);
      } else if (audioTemplateTrack.adelay) {
        adelay = String(audioTemplateTrack.adelay);
      }

      // atempo
      let atempo = '1.00';
      if (filtersApplied.atempo) {
        // prioritize applied atempo filter
        atempo = filtersApplied.atempo;
      } else if (audioTemplateTrack.duration?.durationMax && audioDuration > audioTemplateTrack.duration.durationMax) {
        // check for maximum duration
        atempo = String(Math.min(audioDuration / audioTemplateTrack.duration.durationMax, 2.0));
      } else if (audioTemplateTrack.duration?.durationMin && audioTemplateTrack.duration.durationMin && audioDuration < audioTemplateTrack.duration.durationMin) {
        // check for minimum duration
        atempo = String(Math.max(audioDuration / audioTemplateTrack.duration.durationMin, 0.5));
      }

      // console.debug('ℹ️', {
      //   audioTemplateTrackId: audioTemplateTrack.id,
      //   duration: {
      //     default: audioTemplateTrack.duration,
      //     audio: audioDuration,
      //   },
      //   adelay: {
      //     default: audioTemplateTrack.adelay,
      //     applied: filtersApplied.adelay,
      //   },
      //   atempo: {
      //     default: atempo,
      //     applied: filtersApplied.atempo,
      //   },
      //   volume,
      // });

      audioTracks.push({
        name: String(audioTemplateTrack.name),
        trackId: String(spotTrack.trackId),
        regions: [
          {
            id: String(spotTrack.trackId),
            channels,
            filters: {
              volume,
              adelay,
              atempo,
            },
          },
        ],
      });
    }
  }

  return audioTracks;
}

export const loadFFmpeg = async () => {
  try {
    if (window.ffmpeg && window.ffmpeg.loaded) {
      return;
    }

    const ffmpegInstance = new FFmpeg();
    // ffmpegInstance.on('log', ({ message }) => Logger('info', '🎥 ffmpeg', 'message:', message));
    // ffmpegInstance.on('progress', (progress: any) =>
    //   Logger('info', '🎥 ffmpeg', 'progress:', progress)
    // );

    await ffmpegInstance.load();
    Logger('info', `🖥️ ffmpeg`, 'loaded FFmpeg');

    // Store ffmpeg in window object
    window.ffmpeg = ffmpegInstance;
  } catch (error) {
    Logger('error', `🖥️ ffmpeg`, 'loaded FFmpeg', { error });
  }
};

export const unloadFFmpeg = async () => {
  // dispose ffmpeg
  if (window.ffmpeg) {
    try {
      window.ffmpeg.terminate();
      // @ts-ignore
      window.ffmpeg = null;
      Logger('info', `🖥️ ffmpeg`, 'unload FFmpeg');
    } catch (error) {
      Logger('error', `🖥️ ffmpeg`, 'unload FFmpeg', { error });
    }
  }
};

export const listDirectory = async () => {
  if (!window.ffmpeg) await loadFFmpeg();
  return window.ffmpeg?.listDir('/');
};

export const createDirectory = async (directory: string) => {
  if (!window.ffmpeg) await loadFFmpeg();
  return window.ffmpeg?.createDir(directory);
};

export const writeFile = async (name: string, url: string, overwrite: boolean = false) => {
  if (!window.ffmpeg) await loadFFmpeg();
  const filename = `${name}.mp3`;
  const exists = await fileExists(name);

  if (!exists || overwrite) {
    const response = await window.ffmpeg?.writeFile(`${filename}`, await fetchFile(url));
    console.log(`Successfully wrote ${name} into the file system`);
    console.log({ url });
    const directory = await listDirectory();
    Logger('info', `🖥️ ffmpeg`, 'write file', { filename, directory });
    return response;
  } else {
    console.log(`File ${name} already exists in the file system`);
    return null;
  }
};

export const deleteFiles = async (spotId: string, tracks: CampaignSpotTrackType[]) => {
  if (!window.ffmpeg) await loadFFmpeg();

  // delete spot track files
  for (let i = 0; i < tracks.length; i++) {
    const track = tracks[i];
    const exists = await fileExists(String(track.trackId));
    if (exists) {
      await window.ffmpeg?.deleteFile(`${track.trackId}.mp3`);
    }
  }

  // delete spot merged file
  const mixedAudioExists = await fileExists(String(spotId));
  if (mixedAudioExists) {
    await window.ffmpeg?.deleteFile(`${String(spotId)}.mp3`);
  }
};

export const clearDirectory = async () => {
  if (!window.ffmpeg) await loadFFmpeg();

  const getPerformanceResult = startPerformance();
  const dir = await window.ffmpeg?.listDir('/') || [];

  for (let i = 0; i < dir.length; i++) {
    const file = dir[i];

    if (file.name?.includes('.mp3')) {
      await window.ffmpeg?.deleteFile(file.name);
    }
  }

  const directory = await listDirectory();
  Logger('info', `🖥️ ffmpeg`, 'clear directory', { directory, averageTime: getPerformanceResult() });
};

export const writeFiles = async (tracks: CampaignSpotTrackType[], overwrite: boolean = false) => {
  if (!window.ffmpeg) await loadFFmpeg();

  const getPerformanceResult = startPerformance();

  await Promise.all(
    tracks.map(async track => {
      await writeFile(String(track.trackId), api.media.stream(String(track.assetId)), overwrite);
    })
  );

  const dir = await window.ffmpeg?.listDir('/') || [];
  Logger('info', `🖥️ ffmpeg`, 'writeFiles', { averageTime: getPerformanceResult(), dir });
};

export const fileExists = async (name: string) => {
  if (!window.ffmpeg) await loadFFmpeg();

  try {
    const filename = `${name}.mp3`;
    await window.ffmpeg?.readFile(`${filename}`);
    return true;
  } catch (error) {
    return false;
  }
};

export const allFilesExists = async (tracks: CampaignSpotTrackType[]): Promise<boolean> => {
  if (!window.ffmpeg) await loadFFmpeg();

  const results = await Promise.all(
    tracks.map(async (track) => {
      try {
        await window.ffmpeg?.readFile(`${track.trackId}.mp3`);
        return true;
      } catch {
        return false;
      }
    })
  );

  return results.every(Boolean);
};

export const mixFiles = async (spotId: string, audioTemplate: ConfigAudioTemplateType, tracks: CampaignSpotTrackType[]) => {
  if (!window.ffmpeg) await loadFFmpeg();

  const getPerformanceResult = startPerformance();
  Logger('info', `🖥️ ffmpeg`, 'Mix started', { spotId, startTime: performance?.now().toLocaleString() });

  const preparedTracks = await prepareTracksForMixing(audioTemplate, tracks);
  const mix = await amix(spotId, preparedTracks, audioTemplate);

  const averageTime = getPerformanceResult();

  Logger('info', `🖥️ ffmpeg`, 'Mix finished', { spotId, endTime: performance?.now().toLocaleString(), averageTime: getPerformanceResult() });

  // await unloadFFmpeg();

  applicationInsights.trackMetric({ name: 'EditorAudioMixing', average: averageTime });
  applicationInsights.trackEvent({
    name: TRACK_EVENTS.SpotMix,
    properties: {
      preparedTracks,
      audioTemplate,
      averageTime,
    },
  });

  return mix;
};